import React, { useContext } from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import "@styles/Privacidad.css";
import { Link } from "gatsby";
import { IpContext } from "@components/contexts/IpContext";
import SmallBanner from "@components/common/SmallBanner";

export default function Index() {
    const { warningIp } = useContext(IpContext);

    return (
        <>
            <Layout>
                <Seo title="Aviso legal" />
                <SmallBanner title="Aviso legal" filename="bgs/bgPrivacy" />

                <div className="container lg:px-0 py-20">
                    <div className="mb-10 privacidad">
                        <Title className="text-left">Objeto</Title>
                        <Text className="text-justify">
                            El presente aviso legal regula el uso y utilización del sitio web{" "}
                            <a
                                href="https://www.iopos.es"
                                rel="noreferrer noopener"
                                className="link"
                            >
                                https://www.iopos.es
                            </a>
                            , del que es titular y propietario <strong>iOPOS, SL</strong>.
                            <br />
                            <br />
                            La navegación por el sitio web le atribuye a usted la condición de
                            USUARIO del mismo y supone su aceptación plena y sin reservas de todas y
                            cada una de las condiciones publicadas en este aviso legal, advirtiendo
                            que estas condiciones podrán ser modificadas sin notificación previa por
                            parte del propietario del sitio web, en este caso se procederá a su
                            publicación y aviso con la máxima antelación posible.
                            <br />
                            <br />
                            Por ello es recomendable leer atentamente su contenido en caso de desear
                            acceder y hacer uso de la información y los servicios ofrecidos desde
                            este sitio web.
                            <br />
                            <br />
                            El usuario, se obliga a hacer un uso correcto del sitio web de
                            conformidad con las leyes, la buena fe, el orden público, los usos del
                            tráfico y el presente aviso legal, y responderá frente al propietario
                            del sitio web o frente de terceros, de cualesquiera daños y perjuicios
                            que pudieran causarse como consecuencia del incumplimiento de esta
                            obligación.
                            <br />
                            <br />
                            Cualquier utilización distinta a la autorizada está expresamente
                            prohibida, pudiendo el propietario del sitio web denegar o retirar el
                            acceso y su uso en cualquier momento.
                        </Text>
                    </div>

                    <div className="mb-10 aviso-legal">
                        <Title className="text-left">Identificación</Title>
                        <Text className="text-justify">
                            El propietario del sitio web, en cumplimiento de la Ley 34/2002, de 11
                            de julio, de Servicios de la Sociedad de la Información y de Comercio
                            Electrónico, le informa que:
                            <ul className="ml-5 list-decimal mt-5">
                                <li>Su denominación social es iOpos, SL.</li>
                                <li>Su nombre comercial es iOpos.</li>
                                <li>Su NIF es ESB09771155.</li>
                                <li>
                                    Su domicilio social está ubicado en Plaza González Isla, núm.
                                    10, 43895 L'Ampolla, Tarragona.
                                </li>
                            </ul>
                        </Text>
                    </div>

                    <div className="mb-10 aviso-legal">
                        <Title className="text-left">Comunicaciones</Title>
                        <Text className="text-justify">
                            Para comunicarse con nosotros, hemos puesto a su disposición 4 formas de
                            contacto que le indicamos a continuación:
                            <ul className="ml-5 list-decimal mt-5">
                                <li>
                                    Teléfono:{" "}
                                    <a className="link" href="tel:34644507478">
                                        +34 644 50 74 78
                                    </a>
                                    .
                                </li>
                                <li>
                                    WhatsApp:{" "}
                                    <a className="link" href="https://wa.me/34644507478">
                                        +34 644 50 74 78
                                    </a>
                                </li>
                                <li>
                                    Correo electrónico:{" "}
                                    <a className="link" href="mailto:info@iopos.academy">
                                        info@iopos.academy
                                    </a>
                                </li>
                                <li>
                                    Dirección postal: Plaza González Isla, núm. 10, 43895 L'Ampolla,
                                    Tarragona.
                                </li>
                            </ul>
                        </Text>
                    </div>

                    <div className={`mb-10 aviso-legal ${warningIp ? "hidden" : ""}`}>
                        <Title className="text-left">Condiciones de acceso y utilización</Title>
                        <Text className="text-justify">
                            En caso de que cualquier usuario o un tercero considere que existen
                            hechos o circunstancias que revelen el carácter ilícito de la
                            utilización de cualquier contenido y / o de la realización de cualquier
                            actividad en las páginas web incluidas o accesibles a través del sitio
                            web, deberá enviar una notificación al propietario del sitio web
                            identificándose debidamente y especificando las supuestas infracciones.
                            <br />
                            <br />
                            Compra y devoluciones de módulos:
                            <ul className="list-disc ml-5 mt-5">
                                <li>
                                    El pago de los módulos se hará mediante el sistema de pagos{" "}
                                    <strong>Paypal</strong>.
                                </li>
                                <li>
                                    Todo módulo adquirido podrá ser devuelto en un plazo máximo de{" "}
                                    <strong>14 días</strong> desde el día de su compra y siempre y
                                    cuando no se hayan realizado más de <strong>15 test</strong> en
                                    dicho módulo.
                                </li>
                                <li>
                                    En ningún caso se aceptará la compra de un módulo con oferta
                                    tras una devolución del mismo.
                                </li>
                                <li>
                                    Las devoluciones se harán mediante el mismo método de pago, por
                                    lo qué será Paypal el que abone el reembolso en su cuenta (entre
                                    6 y 10 días laborables). Para más información consulta la página
                                    de ayuda de{" "}
                                    <a
                                        rel="noopener noreferrer"
                                        href="https://www.paypal.com/es/smarthelp/article/%C2%BFd%C3%B3nde-est%C3%A1-mi-reembolso-faq1212"
                                        className="link"
                                    >
                                        Paypal
                                    </a>
                                    .
                                </li>
                            </ul>
                        </Text>
                    </div>

                    <div className="mb-10 aviso-legal">
                        <Title className="text-left">
                            Política de privacidad y protección de datos
                        </Title>
                        <Text className="text-justify">
                            Cuando precisemos obtener información por su parte, siempre le
                            solicitaremos que nos la proporcione voluntariamente de forma expresa.
                            Los datos serán recabados a través de los formularios de recogida de
                            datos del sitio web u otras vías. Si lo desea, puede consultar nuestra{" "}
                            <Link className="link" to="/privacidad/politica-de-privacidad">
                                Política de privacidad
                            </Link>
                            .
                        </Text>
                    </div>

                    <div className="mb-10 aviso-legal">
                        <Title className="text-left">Política de cookies</Title>
                        <Text className="text-justify">
                            Este sitio web utiliza cookies para mejorar su funcionalidad, recopilar
                            estadísticas anónimas e integrarse con otros servicios. Si lo desea,
                            puede consultar nuestra{" "}
                            <Link to="/privacidad/politica-de-cookies/" className="link">
                                Política de cookies
                            </Link>
                            .
                        </Text>
                    </div>

                    <div className="aviso-legal">
                        <Title className="text-left">Legislación aplicable</Title>
                        <Text className="text-justify">
                            Las presentes condiciones se rigen por la ley española. Las partes se
                            someten, a su elección, para la resolución de los conflictos y con
                            renuncia a cualquier otro fuero, a los juzgados y tribunales del
                            domicilio del usuario.
                        </Text>
                    </div>
                </div>
            </Layout>
        </>
    );
}
